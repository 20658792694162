import React, { useEffect, useState } from "react";
import { graphql, Link, navigate } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/Layout";
import axios from "axios";
import { stringToSlug } from "../helpers/StringToSlug";
import useSessionstorage from "@rooks/use-sessionstorage";
import { Spinner } from "../components/Spinner/Spinner";
const gStyles = require("../styles/richText.module.scss");

const API_URL =
  process.env.GATSBY_AMXCONNECT_API_URL || "https://dev.amxconnect.com";
const API_KEY = process.env.GATSBY_UCITS_API_KEY;

const url = `${API_URL}/api/UCITSFunds?apiKey=${API_KEY}`;

const Page = ({
  data: {
    strapiUcits: { heading, introCopy, mainCopy, metaData },
  },
}) => {
  const [data, setData] = useState({
    loading: true,
    funds: null,
    error: false,
  });

  const [ucits] = useSessionstorage("ucits", 0);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        setData({ loading: false, funds: res.data, error: false });
      })
      .catch((err) => {
        setData({ loading: false, funds: null, error: true });
        return;
      });
  }, [setData]);

  const redirect = (e, toUrl) => {
    e.preventDefault();
    navigate("/ucits/disclaimer", {
      state: { toUrl },
    });
  };

  let info = data?.error ? (
    <p> Error fetching funds </p>
  ) : (
    <Spinner verticalAdjustment="50px" />
  );

  if (data?.funds) {
    info = (
      <ul>
        {data?.funds?.map((item) => {
          let link = stringToSlug(item.fundName);

          return (
            <li>
              {ucits ? (
                <Link to={`/ucits/${link}`}>{item.fundName} </Link>
              ) : (
                <a href="#" onClick={(e) => redirect(e, `/ucits/${link}`)}>
                  {item.fundName}{" "}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
      }}
    >
      <div className={gStyles.copyWrap}>
        <h1 className={`${gStyles.header} h2 `}>{heading}</h1>
        <div className={gStyles.content}>
          <div className={gStyles.textBlock}>
            {introCopy ? parse(introCopy) : null}

            {info}
          </div>

          <div className={gStyles.textBlock}>
            {mainCopy ? parse(mainCopy) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiUcits {
      introCopy
      mainCopy
      heading

      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;

export default Page;
